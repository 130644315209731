<template>
  <div class="my">
    <div class="container" v-if="orders.length > 0">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="mb-4">{{ $t("my_orders") }}</h1>
        <b-select v-model="orderBy" style="height:20px;">
          <b-select-option :value="{ key: 'created_at', value: 'desc' }">
            Newer first
          </b-select-option>
          <b-select-option :value="{ key: 'created_at', value: 'asc' }">
            Older first
          </b-select-option>
        </b-select>
      </div>
      <template v-if="!isLoading">
        <orderItem v-for="order in orders" :key="order.id" :order="order" />
      </template>
      <template v-if="isLoading">
        <div
          class="d-flex w-100 align-items-center justify-content-center"
          style="padding:100px 0px;"
        >
          <b-spinner
            style="width: 3rem; height: 3rem;"
            variant="warning"
          ></b-spinner>
        </div>
      </template>
      <div class="d-flex justify-content-center mt-4 pt-4">
        <b-button
          v-show="!hideLoadMoreButton"
          style="height:46px; width:280px;line-height: initial;"
          variant="primary"
          :disabled="showMoreLoading"
          @click="getMoreOrders()"
        >
          <b-spinner
            style="width: 24px; height: 24px;"
            variant="warning"
            v-if="showMoreLoading"
          ></b-spinner>
          <span v-else>
            {{ $t("load_more") }}
          </span>
        </b-button>
      </div>
    </div>
    <div class="container" v-else>
      <div
        class="d-flex w-100 align-items-center justify-content-center"
        style="padding:100px 0px;"
      >
        <h1 class="mb-4">{{ $t("no_orders") }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import orderItem from "@/components/lists/order-list-item.vue";
export default {
  components: {
    orderItem,
  },
  data() {
    return {
      isLoading: false,
      showMoreLoading: false,
      orders: [],
      lastOrder: null,
      perPage: 10,
      hideLoadMoreButton: false,
      orderBy: { key: "created_at", value: "desc" },
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  watch: {
    orderBy: {
      deep: true,
      handler() {
        this.getOrders();
      },
    },
  },
  methods: {
    getMoreOrders() {
      this.showMoreLoading = true;
      var uid = this.user.uid;
      firebase
        .firestore()
        .collection("orders")
        .where("user", "==", uid)
        .orderBy(this.orderBy.key, this.orderBy.value)
        .startAfter(this.lastOrder)
        .limit(this.perPage)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.orders.push(doc.data());
          });

          if (snapshot.docs.length < this.perPage) {
            this.hideLoadMoreButton = true;
          }

          //set last item  for pagination
          this.lastOrder = snapshot.docs[snapshot.docs.length - 1];

          this.showMoreLoading = false;
        })
        .catch((error) => {
          this.showMoreLoadingg = false;
          console.log(error);
        });
    },
    getOrders() {
      this.isLoading = true;
      var uid = this.user.uid;
      this.isLoading = true;
      firebase
        .firestore()
        .collection("orders")
        .where("user", "==", uid)
        .orderBy(this.orderBy.key, this.orderBy.value)
        .limit(this.perPage)
        .get()
        .then((snapshot) => {
          this.orders = [];
          snapshot.forEach((doc) => {
            this.orders.push(doc.data());
          });

          //set last item  for pagination
          this.lastOrder = snapshot.docs[snapshot.docs.length - 1];

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getOrders();
    console.log(this.orders)
  },
};
</script>

<style lang="scss" scoped></style>
