<template>
    <div class="my">
        <b-overlay :show="isLoading"
          :variant="transparent"
          :blur="'2px'"
          rounded="sm"
          bgColor="#16193e"
          no-wrap> 
        </b-overlay>
        <div class="container">
                <b-form-group class="mb-3" style="width: 100%; max-width: 500px;">
                    <label style="color: white;">Username</label>
                    <div class="form-input" :class="{ error: !valid.displayName && validate}">
                        <b-form-input v-model="user.displayName" />
                        <span v-if="!valid.displayName && validate" style="color: red; margin-top: 5px;">The field must be more than 3 characters</span>
                    </div>
                    
                </b-form-group>
                <b-form-group class="mb-2" style="width: 100%; max-width: 500px;">
                    <label style="color: white;">Real Name</label>
                    <div class="form-input" :class="{ error: !valid.realName && validate}">
                        <b-form-input v-model="user.realName" />
                        <div class="d-flex flex-column">
                            <span v-if="!valid.realName && validate" style="color: red; margin-top: 5px;">The field must be more than 3 characters</span>
                            <small>* for delivery</small>
                        </div>
                    </div>
                </b-form-group>
                <b-form-group class="mb-2" style="width: 100%; max-width: 500px;">
                    <label style="color: white;">Real Last Name</label>
                    <div class="form-input" :class="{ error: !valid.realLastName && validate}">
                        <b-form-input v-model="user.realLastName" />
                        <div class="d-flex flex-column">
                            <span v-if="!valid.realLastName && validate" style="color: red; margin-top: 5px;">The field must be more than 3 characters</span>
                            <small>* for delivery</small>
                        </div>
                    </div>
                </b-form-group>
                <b-form-group class="mb-3" style="width: 100%; max-width: 500px;">
                    <div class="form-input" :class="{ error: !valid.email && validate}">
                        <label style="color: white;">Email</label>
                        <b-form-input disabled v-model="user.email" />
                        <span v-if="!valid.email && validate" style="color: red; margin-top: 5px;">The field must be email adress</span>
                    </div>
                </b-form-group>
                <b-form-group style="width: 100%; max-width: 500px" class="mb-3">
                    <div class="form-input" :class="{ error: !valid.deliveryAddress && validate}">
                        <label style="color: white;">Delivery Address</label>
                        <b-form-input v-model="user.deliveryAddress" />
                        <span v-if="!valid.deliveryAddress && validate" style="color: red; margin-top: 5px;">The field must be more than 10 characters</span>
                    </div>
                </b-form-group>
                <b-form-group style="width: 100%; max-width: 500px;" class="mb-3">
                    <div class="form-input" :class="{ error: !valid.phoneNumber && validate}">
                        <label style="color: white;">Phone Number</label>
                        <b-form-input  v-model="user.phoneNumber" />
                        <span v-if="!valid.phoneNumber && validate" style="color: red; margin-top: 5px;">The field must be phone number</span>
                    </div>
                </b-form-group>
                <b-button style="width: 100%; max-width: 500px" class="mt-3" @click="saveProfile" variant="warning">Save</b-button>
            </div>
    </div>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters } from 'vuex'
import { BFormInput, BFormGroup, BButton, BOverlay } from 'bootstrap-vue'
export default {
    data() {
        return {
            isLoading: false,
            deliveryAddress: '',
            phoneNumber: '',
            validate: false
        }
    },
    components: {
        BFormInput,
        BFormGroup,
        BButton,
        BOverlay
    },
    watch: {
        'user.displayName'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.isUsernameUnique(newVal);
            }
        }
    },
    computed: {
        ...mapGetters({
            user: "user",
        }),
        valid() {
            const valid = {
                displayName: true,
                email: true,
                deliveryAddress: true,
                phoneNumber: true,
                realName: true,
                realLastName: true
            };
            if (this.user.displayName?.length < 3) {
            valid.displayName = false;
            }
            if(this.user.realName?.length < 3) {
                valid.realName = false;
            }
            if(this.user.realLastName?.length < 3) {
                valid.realLastName = false;
            }
            if (!this.user.email.includes('@') || this.user.email?.length < 5) {
            valid.email = false;
            }
            if (this.user.deliveryAddress?.length < 10) {
            valid.deliveryAddress = false;
            }
            if (!this.user.phoneNumber?.match(/^\d{10}$/)) {
            valid.phoneNumber = false;
            }

            return valid;
        },
    },
    
    methods: {
        saveProfile() {
            let valid = true;
            this.validate = true;
            for (const key in this.valid) {
                if (!this.valid[key]) {
                    valid = false;
                }
            }
            // Check if all validations passed
            if(valid) {
                this.isLoading = true; // A data property to show loading state, for instance
                const userDocRef = firebase.firestore().collection('users').doc(this.user.uid);

                userDocRef.set({
                    displayName: this.user.displayName,
                    email: this.user.email,
                    deliveryAddress: this.user.deliveryAddress,
                    phoneNumber: this.user.phoneNumber,
                    realName: this.user.realName,
                    realLastName: this.user.realLastName
                }, { merge: true })
                .then(() => {
                    this.$toast.success('User profile updated successfully!')
                    this.isLoading = false;
                    this.validate = false;
                })
                .catch((error) => {
                    console.error('Error updating user profile:', error);
                    this.isLoading = false;
                });
            } else {
                this.$toast.error('Please fill in all fields correctly');
            }
        },
        async isUsernameUnique (username) {
            const usersRef = firebase.firestore().collection('users')
            let isUnique = true;

            try {
                const snapshot = await usersRef.get();
                snapshot.forEach(doc => {
                    if (doc.data().displayName === username) {
                        isUnique = false;
                        this.valid.displayName = false;
                    }
                });

            } catch (error) {
                console.error("Error checking username uniqueness: ", error);
            }
            if (isUnique === false) {
                this.$toast.error('Username already exists');
            }
            return isUnique;
        }
    }
}
</script>

<style lang="scss">
.form-input.error input {
    background-color: #f86e6e;
}
</style>

