<template>
  <div class="order d-flex">
    <div class="product">
      <div
        class="poster"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/diamond.svg') + ')',
        }"
      >
        <div
          class="poster-image"
          v-if="product.image_url"
          :style="{ backgroundImage: 'url(' + product.image_url + ')' }"
        ></div>
      </div>
    </div>
    <div class="info">
      <h3 class="d-flex align-items-center">
        {{$t('order')}} #{{ order.id }}
        <b-badge variant="success" size="sm" v-if="order.status == 'payed'">{{
          order.status
        }}</b-badge>
        <b-badge variant="danger" size="sm" v-if="order.status == 'fail'">{{
          order.status
        }}</b-badge>
        <b-badge v-if="order.status == 'canceled'" variant="canceled">{{
          order.status
        }}</b-badge>
        <b-badge v-if="order.status == 'completed'" variant="completed">{{
          order.status
        }}</b-badge>
        <b-badge v-if="order.status == 'new'" variant="canceled">{{
          'Payment canceled'
        }}</b-badge>
      </h3>
      <div class="d-flex w-100 flex-wrap">
        <div class="info-group">
          <span>{{$t('round_number')}}</span>
          <strong>{{ order.round_number }}</strong>
        </div>
        <div class="info-group">
          <span>{{$t('selected_number')}}</span>
          <strong>{{ order.seat }}</strong>
        </div>
        <div class="info-group">
          <span>{{$t('reward')}}</span>
          <strong>
            <img src="@/assets/img/coin.png" alt="" />
            {{ order.purchase_reward }}
          </strong>
        </div>
        <div class="info-group">
          <span>{{$t('price')}}</span>
          <strong>
            <span v-if="order.currency == 'coins'">
              <img src="@/assets/img/coin.png" alt="" />
              {{ order.product_price }}
            </span>
            <span v-else>
              {{ order.product_price }}
              USD
            </span>
          </strong>
        </div>
        <div class="info-group">
          <span>Created At</span>
          <strong>{{
            order.created_at.seconds | moment("MMM, D YYYY HH:mm")
          }}</strong>
        </div>
        <div class="info-group" v-if="order.tracking_number">
          <span>Tracking Number</span>
          <strong>{{ order.tracking_number }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  props: ["order"],
  data() {
    return {
      isLoading: false,
      product: {},
    };
  },
  methods: {
    getProduct() {
      this.isLoading = true;
      firebase
        .firestore()
        .collection("products")
        .where("uid", "==", this.order.product)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.product = doc.data();
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getProduct();
  },
};
</script>

<style lang="scss" scoped>
.order {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(#fff, 0.2);
  .info-group {
    color: #888dff;
    font-size: 13px;
    width: 33%;
    min-width: 33%;
    margin-bottom: 15px;
    padding-right: 15px;
    img {
      height: 16px;
    }
    strong {
      color: #fff;
      font-size: 16px;
      display: block;
    }
  }
}
.product {
  margin-right: 20px;
  .poster {
    padding-top: 140%;
    background: #6b6969;
    margin-bottom: 15px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30% auto;
    position: relative;
    width: 128px;
    .poster-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-position: center;
      background-size: cover;
    }
  }
}
h3 {
  font-size: 22px;
  .badge {
    margin-left: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .badge-success {
    background: green;
    font-size: 14px;
  }
  .badge-canceled {
    background: lighten(#f00,40%);
    font-weight: 600;
    color: #F00;
  }
  .badge-completed {
    background: #c7ffc7;
    font-weight: 600;
    font-size: 14px;
    color:#078400;
  }
  .budge-warning {
    background: #f0ad4e;
    font-size: 14px;
    font-weight: 600;
    color: black;

  }
  .badge-danger {
    background: red;
    font-size: 14px;
  }
}
</style>
