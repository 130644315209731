<template>
  <div class="my">
    <template v-if="$route.params.tab == 'orders'">
      <myOrders />
    </template>
    <template v-else-if="$route.params.tab == 'profile'">
      <myProfile />
    </template>
  </div>
</template>

<script>
import myOrders from "@/views/my-components/my-orders.vue";
import myProfile from "@/views/my-components/my-profile.vue";
export default {
  components: {
    myOrders,
    myProfile,
},
};
</script>

<style lang="scss" scoped></style>
